export default class Shain {
  userId: string;
  userName: string;
  affiliation: string;
  company: string;
  sort: string;
  password: string;
  authority: string;
  isValid: string;
  constructor(
    userId = "",
    userName = "",
    affiliation = "",
    company = "",
    sort = "",
    password = "",
    authority = "",
    isValid = ""
  ) {
    this.userId = userId;
    this.userName = userName;
    this.affiliation = affiliation;
    this.company = company;
    this.sort = sort;
    this.password = password;
    this.authority = authority;
    this.isValid = isValid;
  }
}
