<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-radio-group
          v-model="selectAffiliation"
          row
          label="会社情報:"
          prepend-icon="mdi-toggle-switch"
          :disabled="!canEdit('form')"
          v-on:change="onChange()"
        >
          <v-radio label="自社" :value="SelectsAffiliation.Default"></v-radio>
          <v-radio label="協力会社" :value="SelectsAffiliation.Help"></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="userId"
          label="ユーザーID"
          prepend-icon="mdi-account"
          outlined
          :readonly="canadd()"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="userName"
          label="ユーザー名"
          prepend-icon="mdi-account"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-autocomplete
          v-model="selectFilterAuthority"
          :items="filterAuthority"
          label="権限"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          return-object
          :disabled="!canEdit('form') || onflg === false"
        ></v-autocomplete>
        <v-autocomplete
          v-model="selectFilterCompany"
          :items="filterCompany"
          label="協力会社"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          return-object
          :disabled="!canEdit('form') || onflg === true"
        ></v-autocomplete>
        <v-text-field
          v-model.number="sort"
          label="ソート"
          type="number"
          outlined
          prepend-icon="mdi-numeric"
          :disabled="!canEdit('form')"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="パスワード"
          prepend-icon="mdi-lock"
          outlined
          :disabled="!canEdit('pass') || onflg === false"
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
          :disabled="!canEdit('form')"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import Shain from "@/models/Shain";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const SelectsDelete = {
  Delete: 0,
  Default: 1
};

const SelectsAffiliation = {
  Default: 0,
  Help: 1
};

export default {
  name: "ShainDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      filterAuthority: [],
      selectFilterAuthority: [],
      userId: "",
      userName: "",
      selectAffiliation: SelectsAffiliation.Default,
      SelectsAffiliation,
      filterCompany: [],
      selectFilterCompany: [],
      onflg: true,
      selectDelete: SelectsDelete.Default,
      SelectsDelete
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    onChange() {
      if (this.selectAffiliation === 0) {
        this.onflg = true;
      } else {
        this.onflg = false;
      }
    },
    canEdit(param) {
      const authority = this.$store.state.authority;
      const user = this.$store.state.userincode;
      if (authority === "1" || authority === "2" || authority === "3") {
        if (param === "pass") {
          if (this.userId === user) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    },
    complete() {
      console.log("complete", this.userId);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.userId);
      this.$loading();
      let pass = "";
      let authority = "";
      let companycd = "";
      if (this.selectAffiliation == "0") {
        if (this.password === "" || this.password === undefined) {
          pass = this.params.args.password;
        } else {
          pass = this.password;
        }
        if (pass === undefined) {
          await this.$error("パスワードを入力してください。");
          this.$unloading();
          return;
        }
        authority = this.selectFilterAuthority.code;
        if (authority === undefined) {
          await this.$error("権限を選択してください。");
          this.$unloading();
          return;
        }
        companycd = "";
      } else {
        authority = "";
        companycd = this.selectFilterCompany.code;
        if (companycd === undefined) {
          await this.$error("協力会社を選択してください。");
          this.$unloading();
          return;
        }
        pass = "";
      }
      try {
        const param = new Shain(
          this.userId,
          this.userName,
          this.selectAffiliation,
          companycd,
          this.sort,
          pass,
          authority,
          this.selectDelete
        );

        console.log("submit_param", param);
        if (this.add) {
          //await this.postRecord(param);
          const data = await this.$post(this.Paths.shain, param);
          const path = "/list/shain";
          this.$router.push({
            path: path,
            query: { filter: this.params.filter }
          });
        } else {
          //await this.putRecord(param);
          await this.$put(this.Paths.shain, param);
        }
        this.$info("更新しました。", "ユーザーマスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shain";
      // this.$router.push(path);
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getAuthority() {
      console.log("getAutority");
      return await this.$get(this.Paths.codeMasterPulldownAutho);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
      this.filterCompany = filter.filter(e => e.code !== NotSetCode.HLP);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    const test = this.params.args;
    console.log("args", test);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.filterAuthority = await this.getAuthority();
      await this.getCodeMasterItems();
      this.getPullDownItems();

      if (!this.add) {
        const filterd = this.filterAuthority.filter(
          e => e.code === String(test.authority)
        );

        if (filterd.length > 0) {
          this.selectFilterAuthority = filterd[0];
        }

        if (test.company !== null) {
          const CompanyCd = test.company == null ? "0" : test.company;
          const filtercompany = this.filterCompany.filter(
            e => e.code === CompanyCd
          );
          if (filtercompany.length > 0) {
            this.selectFilterCompany.code = filtercompany[0].code;
            this.selectFilterCompany.name = filtercompany[0].name;
          }
        }

        this.userId = test.userId;
        //this.password = test.password;
        this.userName = test.userName;

        this.sort = test.sort;

        if (test.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }

        if (test.affiliation === "0") {
          this.selectAffiliation = this.SelectsAffiliation.Default;
        } else {
          this.selectAffiliation = this.SelectsAffiliation.Help;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
